<template lang="pug">
.app
  Header
  .wrapper
    Navigation
    main.main
      router-view
  Footer
</template>

<script>
import Header from '@/components/appSections/Header.vue';
import Footer from '@/components/appSections/Footer.vue';
import Navigation from '@/components/appSections/Navigation.vue';

export default {
  name: 'default',
  components: { Navigation, Footer, Header },
};
</script>

<style scoped lang="stylus">
.app
  width 1300px
  margin 0 auto
.wrapper
  display flex
  width 100%
.nav
  width 250px
.main
  width 100%
  margin 10px 10px 10px 100px
</style>
