<template lang="pug">
.nav
  .item(v-for="link in links" :key="link.name")
    router-link.nav-link(
      :to="{ name: link.name }"
    ) {{ link.title }}
    .sub-item(v-for="subLink in link.subLinks" :key="subLink.name")
      router-link.sub-link(
        :to="{ name: subLink.name }"
      ) {{ subLink.title }}
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      links: {
        home: {
          name: 'home',
          title: 'Home',
          subLinks: [],
        },
        candidate: {
          name: 'candidates',
          title: 'Candidates',
          subLinks: [
            {
              name: 'candidates-create',
              title: 'Create',
            },
            {
              name: 'candidates-search',
              title: 'Search',
            },
          ],
        },
        vacancies: {
          title: 'Vacancies',
          name: 'vacancies',
          subLinks: [
            {
              name: 'vacancies-create',
              title: 'Create',
            },
          ],
        },
      },
    };
  },
};
</script>

<style scoped lang="stylus">
.nav
  padding-left 30px
  padding-top 40px

.nav-link,
.sub-link
  height 20px
  color blueviolet
  text-decoration none

.item,
.sub-item
  text-align left

.item:not(:last-child)
    margin-bottom 10px

.sub-link
  margin-left 20px

.router-link-active
  color: black
</style>
